export const Design = () => (
  <div>
    <div className="top-content-detail">
      <h1 className="title-detail">DESIGNER</h1>
    </div>
    <div className="main-content-detail">
      <div className="detail-item">
        <h2 className="detail-item-title">JOB DESCRIPTION:</h2>
        <p className="detail-item-content">
          ● Proficient in Figma, Adobe InDesign, Illustrator, Photoshop etc.
        </p>
        <p className="detail-item-content">
          ● Confidence to be bold with their work, have a wide range of design
          and creative capabilities
        </p>
        <p className="detail-item-content">
          ● Minimum 1-2 years of working experience in Creative/ Graphic Design
          or equivalent
        </p>
        <p className="detail-item-content">
          ● Knowledge of UX/UI is an advantage.
        </p>
      </div>

      <div className="detail-item">
        <h2 className="detail-item-title">SALARY & BENEFIT</h2>
        <p className="detail-item-content">● Basic salary: Competitive.</p>
        <p className="detail-item-content">
          ● Monthly food allowance, team building.
        </p>
        <p className="detail-item-content">
          ● Free accommodation with utilities (Water, Electricity, Wifi), Flight
          ticket and Visa processing.
        </p>
        <p className="detail-item-content">
          ● Vietnamese Holidays bonus, Birthday bonus, 13th month Salary.
        </p>
        <p className="detail-item-content">● KPI bonus.</p>
        <p className="detail-item-content">● Medical insurance.</p>
        <p className="detail-item-content">
          ● Annual leave (15 days/year), Sick leave (10 days/years)
          (Regularization).
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">WORKPLACE: </h2>
        <p className="detail-item-content">
          ● Robinsons Summit Center, Ayala Avenue, Makati,Metro Manila,
          Philippines
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">APPLY FOR JOB: </h2>
        <p className="detail-item-content">
          ● contact.eastartechnology@gmail.com
        </p>
      </div>
    </div>
  </div>
);

export const Administrator = () => (
  <div>
    <div className="top-content-detail">
      <h1 className="title-detail">OFFICE ADMINISTRATOR</h1>
    </div>
    <div className="main-content-detail">
      <div className="detail-item">
        <h2 className="detail-item-title">JOB DESCRIPTION:</h2>
        <p className="detail-item-content">
          ● Do visa, customs, transportation and accommodation arrangements for
          new employees.
        </p>
        <p className="detail-item-content">
          ● In charge of guiding and making documents for employees such as visa
          working, medical examinations,...
        </p>
        <p className="detail-item-content">
          ● In charge of expenses for the office, staff accommodation,...
        </p>
        <p className="detail-item-content">
          ● Manage company equipment, supplies and employee accommodation.
        </p>
        <p className="detail-item-content">
          ● Participating in organizing cultural activities of the company such
          as: team building, art - sports, year-end party,...
        </p>
        <p className="detail-item-content">● Employee support.</p>
        <p className="detail-item-content">
          ● Perform duties as directed by superiors.
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">JOB REQUIREMENTS:</h2>
        <p className="detail-item-content">
          ● Communicate fluently in English.
        </p>
        <p className="detail-item-content">
          ● Experienced in recruiting for developer positions.
        </p>
        <p className="detail-item-content">
          ● Proficient in office tools: word, excel,...
        </p>
        <p className="detail-item-content">
          ● Hardworking, agile, proactive at work.
        </p>
        <p className="detail-item-content">
          ● Cheerful personality, sociable and honest.
        </p>
      </div>

      <div className="detail-item">
        <h2 className="detail-item-title">SALARY & BENEFIT</h2>
        <p className="detail-item-content">● Basic salary: Competitive.</p>
        <p className="detail-item-content">
          ● Monthly food allowance, team building.
        </p>
        <p className="detail-item-content">
          ● Free accommodation with utilities (Water, Electricity, Wifi), Flight
          ticket and Visa processing.
        </p>
        <p className="detail-item-content">
          ● Vietnamese Holidays bonus, Birthday bonus, 13th month Salary.
        </p>
        <p className="detail-item-content">● KPI bonus.</p>
        <p className="detail-item-content">● Medical insurance.</p>
        <p className="detail-item-content">
          ● Annual leave (15 days/year), Sick leave (10 days/years)
          (Regularization).
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">WORKPLACE: </h2>
        <p className="detail-item-content">
          ● Robinsons Summit Center, Ayala Avenue, Makati,Metro Manila,
          Philippines
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">APPLY FOR JOB: </h2>
        <p className="detail-item-content">
          ● contact.eastartechnology@gmail.com
        </p>
      </div>
    </div>
  </div>
);

export const Tester = () => (
  <div>
    <div className="top-content-detail">
      <h1 className="title-detail">TESTER (QA QC)</h1>
    </div>
    <div className="main-content-detail">
      <div className="detail-item">
        <h2 className="detail-item-title">JOB DESCRIPTION:</h2>
        <p className="detail-item-content">
          ● Join test activities and tasks as planning, analysis & design,
          implementation & execution, Evaluating & report, test closure
          activities…
        </p>
        <p className="detail-item-content">
          ● Guarantee quality of products to decrease maximum number of bugs
          after releases
        </p>
        <p className="detail-item-content">
          ● Join team activities as daily meetings, planning, qc meetings,
          training…
        </p>
        <p className="detail-item-content">● Test APIs</p>
      </div>
      <h2 className="detail-item-title">JOB REQUIREMENTS:</h2>
      <div className="detail-item">
        <h5 className="requirement">Attitude</h5>
        <p className="detail-item-content">● Think positive, sociable, fun.</p>
        <p className="detail-item-content">
          ● Progressive, eager to learn, hardworking.
        </p>
        <p className="detail-item-content">
          ● Try to complete the job well on the deadline.
        </p>
        <p className="detail-item-content">
          ● There is a high sense of responsibility.
        </p>
        <p className="detail-item-content">
          ● Proactively ask questions and find solutions before doing.
        </p>
      </div>
      <div className="detail-item">
        <h5 className="requirement">Professional requirements</h5>
        <p className="detail-item-content">
          ● At least 1 year working experiences as QC/QA
        </p>
        <p className="detail-item-content">
          ● Experience in drawing test plans, test cases, error notes and
          related reports.
        </p>
        <p className="detail-item-content">
          ● Good understanding of the entire development process, including
          specification, documentation and quality assurance.
        </p>
        <p className="detail-item-content">
          ● Ability to manage multiple assigned tasks, attention to detail.
        </p>
      </div>
      <div className="detail-item">
        <h5 className="requirement">Skill</h5>
        <p className="detail-item-content">● Teamwork skill.</p>
        <p className="detail-item-content">● Creative thinking skills.</p>
        <p className="detail-item-content">
          ● Analytical and problem solving skills.
        </p>
      </div>

      <div className="detail-item">
        <h2 className="detail-item-title">SALARY & BENEFIT</h2>
        <p className="detail-item-content">● Basic salary: Competitive.</p>
        <p className="detail-item-content">
          ● Monthly food allowance, team building.
        </p>
        <p className="detail-item-content">
          ● Free accommodation with utilities (Water, Electricity, Wifi), Flight
          ticket and Visa processing.
        </p>
        <p className="detail-item-content">
          ● Vietnamese Holidays bonus, Birthday bonus, 13th month Salary.
        </p>
        <p className="detail-item-content">● KPI bonus.</p>
        <p className="detail-item-content">● Medical insurance.</p>
        <p className="detail-item-content">
          ● Annual leave (15 days/year), Sick leave (10 days/years)
          (Regularization).
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">WORKPLACE: </h2>
        <p className="detail-item-content">
          ● Robinsons Summit Center, Ayala Avenue, Makati,Metro Manila,
          Philippines
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">APPLY FOR JOB: </h2>
        <p className="detail-item-content">
          ● contact.eastartechnology@gmail.com
        </p>
      </div>
    </div>
  </div>
);

export const FrontEnd = () => (
  <div>
    <div className="top-content-detail">
      <h1 className="title-detail">FRONT-END DEVELOPER</h1>
    </div>
    <div className="main-content-detail">
      <div className="detail-item">
        <h2 className="detail-item-title">JOB DESCRIPTION</h2>
        <p className="detail-item-content">
          ● Builds beautiful and responsive web applications using Angular,
          HTML5 and CSS3
        </p>
        <p className="detail-item-content">
          ● Build efficient mobile applications using React Native, Redux
        </p>
        <p className="detail-item-content">
          ● Collaborates with leads to explore existing design, determines areas
          of complexity, potential risks to successful implementation, learns
          the applications capabilities
        </p>
        <p className="detail-item-content">
          ● Assists in defining software architectures
        </p>
        <p className="detail-item-content">
          ● Writes well designed, self-documented, testable, efficient code
        </p>
        <h5 className="requirement">Our tools and stack</h5>
        <p className="detail-item-content">
          ● Our standard working environment is Ubuntu.
        </p>
        <p className="detail-item-content">
          ● Our websites are built using Angular, and our mobile apps are built
          using React Native. The services layer is developed using Spring and
          Java 8. And the database is on PostgreSQL.
        </p>

        <p className="detail-item-content">
          ● We serve our web apps via a Nginx container run in Docker Swarm
          network consisting of CentOS servers.
        </p>

        <p className="detail-item-content">
          ● The source code is hosted on gitlab.com, built, tested and deployed
          via Gitlab CI. Everything from ideas, planning to develop and
          deployment is done on Gitlab.
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">JOB REQUIREMENT</h2>
        <h5 className="requirement">Experience</h5>
        <p className="detail-item-content">
          ● At least 2-year experience in Web development
        </p>
        <p className="detail-item-content">
          ● At least 1-year experience in Angular version 2 and above, or 1-year
          experience in React, React Native.
        </p>
        <h5 className="requirement">Mindset</h5>

        <p className="detail-item-content">
          ● Be responsible. If anything is wrong, first check your side, then
          check again before blaming someone else.
        </p>
        <p className="detail-item-content">
          ● Be a perfectionist. You don't have to write perfect code for the
          first time, but you should want to make it perfect, and spend your
          time reviewing and improving your work, until it's perfect.
        </p>

        <p className="detail-item-content">
          ● Never stop improving yourself. You know Gitlab? Good. How about git
          bisect, git rev-parse, git reflog?
        </p>
        <h5 className="requirement">Skill Mandatory</h5>
        <div className="detail-item-content">● ES6, HTML5, CSS3</div>
        <div className="detail-item-content">● Git</div>
        <div className="detail-item-content">● Nodejs, Yarn, npm</div>

        <div className="detail-item-content">● Chrome DevTools</div>

        <div className="detail-item-content">
          ● At least one tool for API testing, for example: Postman, curl or
          httpie
        </div>

        <div className="detail-item-content">
          ● Work and live on Linux. Our standard working environment is Ubuntu,
          and our application runs in Docker Swarm on CentOS.
        </div>
        <h5 className="requirement">Optional</h5>
        <div className="detail-item-content">
          ● A public profile that contains a demo or showcase of your previous
          works, for example your Github or Gitlab profiles. We want to review
          your code.
        </div>
        <div className="detail-item-content">
          ● Know how a well-designed API looks like.
        </div>
        <div className="detail-item-content">
          ● Build and use Docker containers.
        </div>

        <div className="detail-item-content">
          ● Config Nginx to server website production build
        </div>

        <div className="detail-item-content">
          ● Can work with Photoshop, PSD to handle assets from Designer
        </div>

        <div className="detail-item-content">
          ● Having experience in AJAX, JQuery, Lodash, underscore, ...
        </div>
      </div>

      <div className="detail-item">
        <h2 className="detail-item-title">SALARY & BENEFIT</h2>
        <p className="detail-item-content">● Basic salary: Competitive.</p>
        <p className="detail-item-content">
          ● Monthly food allowance, team building.
        </p>
        <p className="detail-item-content">
          ● Free accommodation with utilities (Water, Electricity, Wifi), Flight
          ticket and Visa processing.
        </p>
        <p className="detail-item-content">
          ● Vietnamese Holidays bonus, Birthday bonus, 13th month Salary.
        </p>
        <p className="detail-item-content">● KPI bonus.</p>
        <p className="detail-item-content">● Medical insurance.</p>
        <p className="detail-item-content">
          ● Annual leave (15 days/year), Sick leave (10 days/years)
          (Regularization).
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">WORKPLACE: </h2>
        <p className="detail-item-content">
          ● Robinsons Summit Center, Ayala Avenue, Makati,Metro Manila,
          Philippines
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">APPLY FOR JOB: </h2>
        <p className="detail-item-content">
          ● contact.eastartechnology@gmail.com
        </p>
      </div>
    </div>
  </div>
);

export const BackEnd = () => (
  <div>
    <div className="top-content-detail">
      <h1 className="title-detail">BACK-END DEVELOPER</h1>
    </div>
    <div className="main-content-detail">
      <div className="detail-item">
        <h2 className="detail-item-title">JOB DESCRIPTION</h2>
        <p className="detail-item-content">
          ● Develop the most effective technical solutions to meet business
          requirements.
        </p>
        <p className="detail-item-content">
          ● Provide leadership on product development projects designing and
          delivering technology solutions in the mobile space.
        </p>
        <p className="detail-item-content">
          ● Participate and lead project estimating activities, software design
          reviews, code reviews to ensure that solutions are designed to meet
          business needs.
        </p>
        <p className="detail-item-content">
          ● Unit-test code for robustness, including edge cases, usability, and
          general reliability.
        </p>
        <p className="detail-item-content">
          ● Continuously discover, evaluate, and recommend new technologies to
          ensure quality and productivity.
        </p>
        <p className="detail-item-content">
          ● Analyze and troubleshoot application issues in a timely fashion.
        </p>
      </div>

      <div className="detail-item">
        <h2 className="detail-item-title">JOB REQUIREMENT</h2>
        <p className="detail-item-content">
          ● Bachelor’s degree in Computer Science or related field
        </p>
        <p className="detail-item-content">
          ● Research new features, analyze requirements, estimate
          implementation; design software; code reviews; document solutions
        </p>
        <p className="detail-item-content">
          ● At least 1-3 year experience with Java language
        </p>
        <p className="detail-item-content">
          ● Strong skills in web technologies, JEE, SQL Database
        </p>
        <p className="detail-item-content">
          ● Knowledge of JSF, Socket.IO, Websocket are an advantage
        </p>
        <p className="detail-item-content">
          ● Good background knowledge of OOP, critical and logical thinking
          skills.
        </p>
        <p className="detail-item-content">
          ● Experience working with REST and JSON, have the ability to
          understand the backend services/system/infrastructure
        </p>
        <p className="detail-item-content">
          ● Good communication and interpersonal skills
        </p>
        <p className="detail-item-content">
          ● Enjoy working in a team environment.
        </p>
      </div>

      <div className="detail-item">
        <h2 className="detail-item-title">SALARY & BENEFIT</h2>
        <p className="detail-item-content">● Basic salary: Competitive.</p>
        <p className="detail-item-content">
          ● Monthly food allowance, team building.
        </p>
        <p className="detail-item-content">
          ● Free accommodation with utilities (Water, Electricity, Wifi), Flight
          ticket and Visa processing.
        </p>
        <p className="detail-item-content">
          ● Vietnamese Holidays bonus, Birthday bonus, 13th month Salary.
        </p>
        <p className="detail-item-content">● KPI bonus.</p>
        <p className="detail-item-content">● Medical insurance.</p>
        <p className="detail-item-content">
          ● Annual leave (15 days/year), Sick leave (10 days/years)
          (Regularization).
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">WORKPLACE: </h2>
        <p className="detail-item-content">
          ● Robinsons Summit Center, Ayala Avenue, Makati,Metro Manila,
          Philippines
        </p>
      </div>
      <div className="detail-item">
        <h2 className="detail-item-title">APPLY FOR JOB: </h2>
        <p className="detail-item-content">
          ● contact.eastartechnology@gmail.com
        </p>
      </div>
    </div>
  </div>
);
