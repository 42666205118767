import { useState } from "react";
import "./Navbar.scss";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import Popup from "components/Popup/Popup";
import Form from "components/Form/Form";

function Navbar() {
  const navigate = useNavigate();
  const [isShowMenuMobile, setIsShowMenuMobile] = useState(false);
  const [showPopupContact, setshowPopupContact] = useState(false);

  const handleNavigate = (path) => {
    navigate(path);
    setIsShowMenuMobile(false);
  };

  const handleShowPopupContact = () => {
    setshowPopupContact(true);
  };

  return (
    <>
      <div className="navbar-wrapper">
        <div
          className={classNames("backdrop", {
            show: isShowMenuMobile === true,
          })}
          onClick={() => setIsShowMenuMobile(!isShowMenuMobile)}
        ></div>
        <div className="navbar-container">
          <div className="navbar-left">
            <img
              src={require("assets/icons/logo.png")}
              className="logo-img"
              alt=""
              onClick={() => navigate("/")}
            />
          </div>
          <div className="navbar-right">
            <ul className="navbar-ul">
              <li className="navbar-li">
                <a className="navbar-a" onClick={() => handleNavigate("/")}>
                  Home
                </a>
              </li>
              <li className="navbar-li">
                <a
                  href="#sec2"
                  className="navbar-a"
                  onClick={() => handleNavigate("/")}
                >
                  Services
                </a>
              </li>
              <li className="navbar-li">
                <a
                  href="#sec3"
                  className="navbar-a"
                  onClick={() => handleNavigate("/")}
                >
                  About Us
                </a>
              </li>
              <li className="navbar-li">
                <a
                  href="#sec4"
                  className="navbar-a"
                  onClick={() => handleNavigate("/")}
                >
                  Clients
                </a>
              </li>
              <li className="navbar-li">
                <a className="navbar-a" onClick={() => handleNavigate("/job")}>
                  Jobs
                </a>
              </li>
              <li className="navbar-li">
                <button
                  type="button"
                  className="contact-btn"
                  onClick={handleShowPopupContact}
                >
                  CONTACT US
                </button>
              </li>
            </ul>
          </div>
          <div className="navbar-mobile">
            <button
              className="btn-hamburger"
              onClick={() => setIsShowMenuMobile(!isShowMenuMobile)}
            >
              <img
                src={require("assets/icons/hamburger.png")}
                className="icon-hamburger"
                alt=""
              />
            </button>
            <ul
              className={classNames("navbar-ul", {
                show: isShowMenuMobile === true,
              })}
            >
              <li className="navbar-li">
                <a
                  href="#sec1"
                  className="navbar-a"
                  onClick={() => handleNavigate("/")}
                >
                  Home
                </a>
              </li>
              <li className="navbar-li">
                <a
                  href="#sec2"
                  className="navbar-a"
                  onClick={() => handleNavigate("/")}
                >
                  Services
                </a>
              </li>
              <li className="navbar-li">
                <a
                  href="#sec3"
                  className="navbar-a"
                  onClick={() => handleNavigate("/")}
                >
                  About Us
                </a>
              </li>
              <li className="navbar-li">
                <a
                  href="#sec4"
                  className="navbar-a"
                  onClick={() => handleNavigate("/")}
                >
                  Clients
                </a>
              </li>
              <li className="navbar-li">
                <a className="navbar-a" onClick={() => handleNavigate("/job")}>
                  Jobs
                </a>
              </li>
              <li className="navbar-li">
                <button
                  type="button"
                  className="contact-btn"
                  onClick={handleShowPopupContact}
                >
                  CONTACT US
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showPopupContact && (
        <Popup
          isShow={showPopupContact}
          center
          onClose={() => setshowPopupContact(false)}
        >
          <Form
            type="CONTACT"
            popup
            onClose={() => setshowPopupContact(false)}
          />
        </Popup>
      )}
    </>
  );
}

export default Navbar;
