import { useEffect, useState } from "react";
import "./ContactUs.scss";
import classNames from "classnames";

import Form from "components/Form/Form";

function ContactUs(props) {
  const { scrollPosition } = props;

  const [classNameShow, setClassNameShow] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 450) {
      if (scrollPosition >= 4800) {
        setClassNameShow(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 6700) {
        setClassNameShow(true);
      }
    } else {
      if (scrollPosition >= 4200) {
        setClassNameShow(true);
      }
    }
  }, [scrollPosition]);

  return (
    <section id="sec7" className="contact-wrapper">
      <div className="contact-container">
        <div
          className={classNames("contact-content", { animate: classNameShow })}
        >
          <div className="form-wrapper">
            <Form type="CONTACT" />
          </div>
          <div className="contact-title">
            <h2 className="title-h2">Contact Us</h2>
            <p className="title-span">Let us help you !!!</p>
            <p className="title-p">
              To learn more about Wasp’s consulting services, please fill out
              the contact form and a member of the team will be in touch soon.
            </p>
          </div>
        </div>
        <img
          src={require("assets/icons/contact-bg.png")}
          className="contact-bg"
          alt=""
        />
      </div>
    </section>
  );
}

export default ContactUs;
