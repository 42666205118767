import Sec1 from "components/Sec1/Sec1";
import "./HomePage.scss";
import Sec2 from "components/Sec2/Sec2";
import Sec3 from "components/Sec3/Sec3";
import Sec4 from "components/Sec4/Sec4";
import Sec5 from "components/Sec5/Sec5";
import Sec6 from "components/Sec6/Sec6";
import ContactUs from "components/ContactUs/ContactUs";

function HomePage(props) {
  const { delayAnimation, scrollPosition } = props;

  return (
    <div className="home-page">
      <Sec1 delayAnimation={delayAnimation} />
      <Sec2 scrollPosition={scrollPosition} />
      <Sec3 scrollPosition={scrollPosition} />
      <Sec4 scrollPosition={scrollPosition} />
      <Sec5 scrollPosition={scrollPosition} />
      <Sec6 scrollPosition={scrollPosition} />
      <ContactUs scrollPosition={scrollPosition} />
    </div>
  );
}

export default HomePage;
