import "./Form.scss";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input";
import classNames from "classnames";
import PopupSubmitSuccess from "components/Popup/PopupSubmitSuccess/PopupSubmitSuccess";
import Popup from "components/Popup/Popup";

function Form(props) {
  const { type, popup, onClose } = props;

  const form = useRef();
  const recaptchaRef = useRef();

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const [isSend, setIsSend] = useState(false);
  const [isShowPopupSuccess, setIsShowPopupSuccess] = useState(false);

  const [isError, setIsError] = useState(0);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [phoneValue, setPhoneValue] = useState(null);

  const handleClosePopup = (e) => {
    e.preventDefault();
    setIsShowPopupSuccess(false);
    setIsSend(false);
  };

  const changeFile = (e) => {
    setIsError(0);
    uploadImage(e.files[0]);
  };

  const uploadImage = (file) => {
    const url = "https://file.io/";

    const formData = new FormData();

    if (file.size > 1024 * 1024 * 30) {
      setIsError(4);
      setFile(null);
      setFileUrl(null);
      return;
    }

    formData.append("file", file);

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        setIsError(0);
        setFileUrl(result.link);
        setFile(result.name);
        console.log(result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const name = e.target.user_name.value;
    const phone = phoneValue;
    const email = e.target.user_email.value;
    const message = e.target.message.value;

    const recaptchaToken = recaptchaValue;

    if (!name.trim()) {
      setIsError(1);
      return;
    }
    if (!email.trim()) {
      setIsError(2);
      return;
    }
    if (phone.length <= 4) {
      setIsError(3);
      return;
    }

    const serviceId = "service_zfv2o7r";
    const userId = "hzHx0pDVjpp5Eq4-v";
    let templateId = "";
    let emailParams = null;

    if (type === "CONTACT") {
      templateId = "template_jmtvadz";
      emailParams = {
        to_name: "Contact Wasp technology",
        to_email: "contact.wasptechnology@gmail.com",
        user_name: name,
        user_email: email,
        user_phone: phone,
        message: message,
        "g-recaptcha-response": recaptchaToken,
      };
    } else {
      templateId = "template_p5ktlfp";
      emailParams = {
        to_name: "HR Wasp technology",
        user_name: name,
        user_email: email,
        user_phone: phone,
        message: message,
        file_url: fileUrl,
        file_name: file,
        "g-recaptcha-response": recaptchaToken,
      };
    }

    if (isRecaptchaVerified) {
      setIsSend(true);
      setIsShowPopupSuccess(true);
      setIsError(0);
      setFile(null);
      setFileUrl(null);
      setIsRecaptchaVerified(false);
      setRecaptchaValue(null);
      recaptchaRef.current.reset();
      onClose();
      emailjs.send(serviceId, templateId, emailParams, userId).then(
        (result) => {
          console.log("Success!");
        },
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset();
    } else {
      return;
    }
  };

  const handleChangeRecaptchaValue = (value) => {
    setIsRecaptchaVerified(true);
    setRecaptchaValue(value);
  };

  const removeFile = () => {
    setFile(null);
    setFileUrl(null);
  };

  return (
    <div className="form-wrapper">
      <form
        className={classNames("form-contact", { width: popup })}
        encType="multipart/form-data"
        ref={form}
        onSubmit={handleSubmitForm}
      >
        <div className="form-item">
          <input
            type="text"
            className="input-form"
            name="user_name"
            placeholder="Enter you name"
          />
          {isError === 1 && <span className="error-msg">*Error message</span>}
        </div>
        <div className="form-item">
          <input
            type="email"
            className="input-form"
            name="user_email"
            placeholder="Enter you email"
          />
          {isError === 2 && <span className="error-msg">*Error message</span>}
        </div>
        <div className="form-item">
          <PhoneInput
            className="input-form"
            countrySelectProps={{ unicodeFlags: true }}
            onChange={setPhoneValue}
            international
            countryCallingCodeEditable={false}
            defaultCountry="VN"
            placeholder="Nhập số điện thoại..."
          />
          {isError === 3 && <span className="error-msg">*Error message</span>}
        </div>
        {type === "APPLY_JOB" && (
          <div className="upload-file">
            <div className="title-input-upload">
              <div className="cate-upload">
                CV{" "}
                {file && (
                  <span className="name-file-upload">
                    {file}
                    <img
                      src={require("assets/icons/remove-file.png")}
                      className="icon-remove"
                      onClick={removeFile}
                      alt=""
                    />
                  </span>
                )}
                {isError === 4 && (
                  <span
                    style={{
                      marginTop: "8px",
                      textAlign: "left",
                      width: "100%",
                    }}
                    className="error-msg"
                  >
                    *Your file is too large, Please send the driver link or send
                    it directly via email contact.eastartechnology@gmail.com
                  </span>
                )}
              </div>
              <p className="note-support">
                Supported format: PDF, DOC, DOCX, ZIP, RAR - Max size: 30MB
              </p>
            </div>
            <div className="upload-input">
              <label className="btn-upload" htmlFor="upload">
                Upload
                <input
                  type="file"
                  className="input-file"
                  name="file_upload"
                  onChange={(e) => changeFile(e.target)}
                  accept=".doc, .docx, .zip, .rar, .pdf"
                  id="upload"
                />
              </label>
            </div>
          </div>
        )}
        <div className="form-item">
          <textarea
            name="message"
            rows={5}
            className="input-form"
            placeholder="More detail"
          ></textarea>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          onChange={handleChangeRecaptchaValue}
          sitekey="6Lfc5gEnAAAAAJhMXNlMDvAvS4OWv_vkv5y52aeu"
        />
        <div className="form-btn">
          {popup && (
            <button type="button" onClick={onClose} className="btn-cancel">
              Cancel
            </button>
          )}
          <button
            type="submit"
            className={classNames("btn-send", { sented: isSend === true })}
          >
            <div className="btn-arrow">
              <img
                src={require("assets/icons/arrow_forward.png")}
                className="icon-arrow"
                alt=""
              />
              <img
                src={require("assets/icons/check.png")}
                className="icon-check"
                alt=""
              />
            </div>
            <p className="sent">Sent</p>
            <p className="send">Send</p>
          </button>
        </div>
      </form>
      {isShowPopupSuccess && (
        <Popup
          isShow={isShowPopupSuccess}
          onClose={handleClosePopup}
          center
          notBackdrop
        >
          <PopupSubmitSuccess onClose={handleClosePopup} />
        </Popup>
      )}
    </div>
  );
}

export default Form;
