import { useNavigate } from "react-router-dom";
import "./Jobs.scss";
import dataJobs from "../../data/jobs.json";
import { useEffect, useState } from "react";

function Jobs() {
  const navigate = useNavigate();
  const [listJobs, setListJobs] = useState();

  useEffect(() => {
    if (dataJobs.length > 0) {
      setListJobs(dataJobs);
    }
  }, []);

  const handleSelectJob = (id) => {
    // const params = {
    //   pathname: `/detail-job/${id}`,
    //   search: ``,
    //   hash: "",
    //   state: id,
    // };
    navigate(`/detail-job/${id}`);

  };

  return (
    <div className="jobs-page">
      <div className="jobs-wrapper">
        <div className="header-page">
          <div className="header-left">
            <div className="title-header">
              We build <b>world-class tech products</b> used and loved by
              millions. Join us and find meaning in your works.
            </div>
          </div>
          <div className="header-right">
            <img
              className="img-office"
              alt=""
              src={require("assets/icons/office.png")}
            />
          </div>
        </div>
        <div className="content-page">
          {/* <div className="filter-bar">
          <div className="filter-content flex-column">
            <div className="header-filter flex-between">
              <h4>By team</h4>
              <img
                src={require("assets/icons/add.png")}
                className="icon-add"
                alt=""
              />
            </div>
            <div className="list-filter">
              <label className="filter-item" htmlFor="team1">
                <input
                  type="checkbox"
                  name="cate-filter"
                  className="checkbox-input"
                  id="team1"
                />
                <span className="label-input">Engineering</span>
              </label>
              <div className="filter-item">
                <input
                  type="checkbox"
                  name="cate-filter"
                  className="checkbox-input"
                  id=""
                />
                <label htmlFor="" className="label-input">
                  Product & Design
                </label>
              </div>
              <div className="filter-item">
                <input
                  type="checkbox"
                  name="cate-filter"
                  className="checkbox-input"
                  id=""
                />
                <label htmlFor="" className="label-input w100">
                  Business
                </label>
              </div>
              <div className="filter-item">
                <input
                  type="checkbox"
                  name="cate-filter"
                  className="checkbox-input"
                  id=""
                />
                <label htmlFor="" className="label-input">
                  Business Support
                </label>
              </div>
            </div>
          </div>
        </div> */}
          <div className="jobs-wrapper">
            <div className="header-list-jobs">
              <b>Jobs</b> Opening ({listJobs?.length})
            </div>
            <div className="list-jobs">
              {listJobs?.map((job) => (
                <div
                  className="item-job"
                  onClick={() => handleSelectJob(job.id)}
                  key={job.id}
                >
                  <div className="top-item">
                    <div className="left-top-item">
                      <img
                        src={require("assets/icons/location.png")}
                        className="icon-location"
                        alt=""
                      />
                      <span className="text-location">{job.location}</span>
                    </div>
                    {/* <div className="right-top-item">
                    <div className="time-work">
                      <span>Full-time</span>
                    </div>
                  </div> */}
                  </div>
                  <div className="content-item">
                    <span className="title-job">{job.title}</span>
                  </div>
                  <div className="bottom-item">
                    <p className="detail-job">{job.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jobs;
