import classNames from "classnames";
import "./PopupService.scss";

function PopupService(props) {
  const { data, onClose } = props;

  return (
    <div className="popup-service">
      <div
        className={classNames("header-popup", {
          yellow: data.bg === 1,
          red: data.bg === 2,
          green: data.bg === 3,
        })}
      >
        <div className="img-wrapper">
          <img src={data.imgPopup} alt="" className="img-popup" />
        </div>
        <button type="button" className="btn-close" onClick={onClose}>
          <img
            src={require("assets/icons/close_white.png")}
            className="icon-close"
            alt=""
          />
        </button>
      </div>
      <div className="bottom-popup">
        <div className="title-popup">{data.name}</div>
        <p className="content-popup">{data.content}</p>
      </div>
    </div>
  );
}

export default PopupService;
