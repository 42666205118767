import classNames from "classnames";
import "./Sec2.scss";
import { useEffect, useState } from "react";
import Popup from "components/Popup/Popup";
import PopupService from "components/Popup/PopupService/PopupService";

let serviceList = [
  {
    id: 1,
    name: "Digital Marketing",
    image: require("assets/icons/digital.png"),
    content:
      "We provide comprehensive digital marketing services tailored to help businesses succeed in the online landscape. Our team of experienced professionals combines creativity, strategy, and technical expertise to deliver measurable results and drive business growth.",
    imgPopup: require("assets/icons/dig_mkt.png"),
    bg: 1,
  },
  {
    id: 2,
    name: "Financial Planing",
    image: require("assets/icons/plan.png"),
    content:
      "We offer comprehensive financial planning services to help businesses achieve their financial goals and make informed decisions. Our team of experienced financial experts combines industry knowledge, data analysis, and strategic thinking to develop tailored financial plans that drive growth and ensure long-term success.",
    imgPopup: require("assets/icons/planing.png"),
    bg: 2,
  },
  {
    id: 3,
    name: "Set Optimization",
    image: require("assets/icons/optimization.png"),
    content:
      "We specialize in set optimization services to help businesses streamline their operations, maximize efficiency, and drive performance improvements. Our team of experts combines industry knowledge, data analysis, and strategic thinking to deliver customized solutions that enhance productivity, reduce costs, and optimize resource allocation.",
    imgPopup: require("assets/icons/optimization.png"),
    bg: 3,
  },
  {
    id: 4,
    name: "Business Consulting",
    image: require("assets/icons/business.png"),
    content:
      "We provide comprehensive business consulting services to help organizations overcome challenges, capitalize on opportunities, and achieve sustainable growth. Our team of experienced consultants combines industry expertise, strategic thinking, and data-driven insights to deliver tailored solutions that drive business transformation and maximize value.",
    imgPopup: require("assets/icons/business.png"),
    bg: 2,
  },
  {
    id: 5,
    name: "Marketing Research",
    image: require("assets/icons/marketing.png"),
    content:
      "We specialize in providing comprehensive marketing research services to help businesses gain valuable insights, understand customer behavior, and make informed marketing decisions. Our team of experienced researchers combines industry knowledge, data analysis, and strategic thinking to deliver customized solutions that drive business growth and maximize return on investment.",
    imgPopup: require("assets/icons/research 1.png"),
    bg: 1,
  },
  {
    id: 6,
    name: "UX Research",
    image: require("assets/icons/research.png"),
    content:
      "We specialize in providing comprehensive UX research services to help businesses create user-centered experiences and optimize their digital products. Our team of experienced UX researchers combines human-centered design principles, industry best practices, and data-driven insights to deliver actionable recommendations that enhance user satisfaction and drive business success.",
    imgPopup: require("assets/icons/research_1 1.png"),
    bg: 3,
  },
];

function Sec2(props) {
  const { scrollPosition } = props;

  const [showPopupDetail, setShowPopupDetail] = useState(false);
  const [classNameShow, setClassNameShow] = useState(false);
  const [detailItem, setDetailItem] = useState({});

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 450) {
      if (scrollPosition >= 650) {
        setClassNameShow(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 1020) {
        setClassNameShow(true);
      }
    } else {
      if (scrollPosition >= 700) {
        setClassNameShow(true);
      }
    }
  }, [scrollPosition]);

  const handleShowPopupDetail = (data) => {
    setDetailItem(data);
    setShowPopupDetail(true);
  };

  return (
    <section id="sec2" className="sec2-wrapper">
      <div className="sec2-container">
        <div className="sec2-title">
          <div className="header-title">
            <h3 className="title-top">Services</h3>
            <div className="title-bottom">
              Why choose Us
              <img
                className="icon-title"
                src={require("assets/icons/title-animate.png")}
                alt=""
              />
            </div>
          </div>
          <div className="footer-title">WE CAN HELP YOUR BUSINESS GROW</div>
        </div>
        <div className="sec2-content">
          <div className="list-services">
            {serviceList.length > 0 &&
              serviceList.map((item) => (
                <div
                  className={classNames("service-item", {
                    mb: item.id < 4,
                    show: classNameShow,
                  })}
                  key={item.id}
                  onClick={() => handleShowPopupDetail(item)}
                >
                  <div className="hidden-icon"></div>
                  <img
                    src={require("assets/icons/btn-more.png")}
                    className="icon-more"
                    alt=""
                  />
                  <div className="img-wrapper">
                    <img src={item.image} className="img-service" alt="" />
                  </div>
                  <p className="name-service">{item.name}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      {showPopupDetail && (
        <Popup isShow={showPopupDetail} center>
          <PopupService
            data={detailItem}
            onClose={() => setShowPopupDetail(false)}
          />
        </Popup>
      )}
    </section>
  );
}

export default Sec2;
