import { useEffect, useState } from "react";
import "./Sec4.scss";
import classNames from "classnames";
import { SwiperSlide, Swiper, useSwiper } from "swiper/react";
import { FreeMode, Navigation } from "swiper";

const listMembers = [
  {
    id: 1,
    name: "Michael",
    img: require("assets/members/member1.jpg"),
    position: "CEO",
  },
  {
    id: 2,
    name: "Chris",
    img: require("assets/members/member2.jpg"),
    position: "CTO",
  },
  {
    id: 3,
    name: "Mina",
    img: require("assets/members/member3.jpg"),
    position: "QC/QA",
  },
];

function Sec4(props) {
  const { scrollPosition } = props;

  const [idShowMember, setIdShowMember] = useState(1);
  const [classNameShow, setClassNameShow] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 450) {
      if (scrollPosition >= 2500) {
        setClassNameShow(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 4000) {
        setClassNameShow(true);
      }
    } else {
      if (scrollPosition >= 2200) {
        setClassNameShow(true);
      }
    }
  }, [scrollPosition]);

  const handleHoverMember = (id) => {
    setIdShowMember(id);
  };

  return (
    <section id="sec4" className="sec4-wrapper">
      <div className="bg-header">
        <div className="bg-content-wrapper">
          <div className={classNames("bg-title", { animate: classNameShow })}>
            <h3 className="title-top">About us</h3>
            <div className="title-bottom">Why choose Us</div>
          </div>
        </div>
      </div>
      <div className="sec4-container">
        <div className={classNames("team-slogan", { animate: classNameShow })}>
          WE HAVE <b>A PROFESSIONAL TEAM OF BUSINESS ANALYSTS. </b>
        </div>
        <div className={classNames("list-member", { animate: classNameShow })}>
          <div className="members">
            {listMembers.map((item) => (
              <div
                key={item.id}
                className={classNames("member-item", {
                  show: item.id === idShowMember,
                })}
                onMouseEnter={() => handleHoverMember(item.id)}
              >
                <img src={item.img} alt="" className="img-member" />
                <div className="info-member">
                  <h2 className="name-member">{item.name}</h2>
                  <p className="position-member-hover">{item.position}</p>
                  <p className="position-member">{item.position}</p>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="btn-wrapper">
            <button className="btn-action prev" type="button">
              <img
                src={require("assets/icons/arrow_back.png")}
                className="icon-arrow"
                alt=""
              />
            </button>
            <button className="btn-action next" type="button">
              <img
                src={require("assets/icons/arrow_forward.png")}
                className="icon-arrow"
                alt=""
              />
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Sec4;
