import "./Sec6.scss";

const listCustomerComment = [
  {
    id: 1,
    name: "Alex",
    comment:
      "I had a wonderful experience with your product/service! The quality exceeded my expectations, and the customer support was exceptional. Highly recommended!",
    img: require("assets/members/customer1.png"),
  },
  {
    id: 2,
    name: "Alvin",
    comment:
      "I've been a loyal customer for years, and I'm always impressed by the consistency of your product/service. Thank you for delivering top-notch quality every time!",
    img: require("assets/members/customer2.png"),
  },
  {
    id: 3,
    name: "Ralph",
    comment:
      "I'm extremely impressed by the level of professionalism and attention to detail I experienced with your company. From start to finish, the entire process was smooth and efficient. Great job!",
    img: require("assets/members/customer3.png"),
  },
];

function Sec6() {
  return (
    <section id="sec6" className="sec6-wrapper">
      <div className="sec6-container">
        <div className="title-sec6">
          <h1 className="title-text">What our customer say.</h1>
          <img
            src={require("assets/icons/dot.png")}
            className="icon-dot"
            alt=""
          />
        </div>
        <div className="customers">
          <div className="list-customer">
            {listCustomerComment.map((item, index) => (
              <div className="customer-item" key={index}>
                <p className="content-item">{item.comment}</p>
                <div className="info-customer">
                  <img src={item.img} className="img-customer" alt="" />
                  <p className="name-customer">{item.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sec6;
