import { useEffect, useState } from "react";
import "./Sec5.scss";
import classNames from "classnames";

const sec5List = [
  {
    id: 1,
    name: "Nominations",
    img: require("assets/icons/sec5-icon1.png"),
    count: 23,
  },
  {
    id: 2,
    name: "Agency",
    img: require("assets/icons/sec5-icon2.png"),
    count: 31,
  },
  {
    id: 3,
    name: "Awards",
    img: require("assets/icons/sec5-icon3.png"),
    count: 7,
  },
  {
    id: 4,
    name: "Supported",
    img: require("assets/icons/sec5-icon4.png"),
    count: 8,
  },
];

function Sec5(props) {
  const { scrollPosition } = props;
  const [classNameShow, setClassNameShow] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 450) {
      if (scrollPosition >= 3500) {
        setClassNameShow(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 4800) {
        setClassNameShow(true);
      }
    } else {
      if (scrollPosition >= 3000) {
        setClassNameShow(true);
      }
    }
  }, [scrollPosition]);

  return (
    <section id="sec5" className="sec5-wrapper">
      <div className="sec5-container">
        <div className={classNames("sec5-content", { animate: classNameShow })}>
          <img
            src={require("assets/icons/sec5-icon-header.png")}
            className="icon-header"
            alt=""
          />
          <div className="sec5-title">
            <h2 className="title-h2">Creat</h2>
            <p className="title-span">PERSONALISED BUSINESS</p>
            <p className="title-p">
              Business normal distribution of letters, as opposed to using
            </p>
          </div>
          <div className="sec5-list">
            {sec5List.map((item) => (
              <div className="sec5-item" key={item.id}>
                <img src={item.img} alt="" className="icon-item" />
                <div className="info-item">
                  <div className="name-item">{item.name}</div>
                  <div className="count-item">{item.count}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sec5;
