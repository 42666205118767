import classNames from "classnames";
import "./Sec1.scss";
import { useState } from "react";
import Popup from "components/Popup/Popup";
import Form from "components/Form/Form";

function Sec1(props) {
  const { delayAnimation } = props;
  const [showPopupContact, setShowPopupContact] = useState(false);

  const handleShowPopupContact = () => {
    setShowPopupContact(true);
  };

  return (
    <section className="sec1-wrapper" id="sec1">
      <div className="sec1-container">
        <div className={classNames("sec1-left", { animate: delayAnimation })}>
          <div className="sec1-title">
            "<b>Always do more than is required of you!</b>" for your Business
          </div>
          <p className="sec1-content">
            Showcase your Profile to the world using online CV builder and Get
            Hired Faster
          </p>
          <button
            type="button"
            onClick={handleShowPopupContact}
            className="contact-btn"
          >
            CONTACT US
          </button>
        </div>
        <div className={classNames("sec1-right", { animate: delayAnimation })}>
          <img
            src={require("assets/icons/sec1.png")}
            className="img-sec1"
            alt=""
          />
        </div>
      </div>
      {showPopupContact && (
        <Popup
          isShow={showPopupContact}
          center
          onClose={() => setShowPopupContact(false)}
        >
          <Form
            type="CONTACT"
            popup
            onClose={() => setShowPopupContact(false)}
          />
        </Popup>
      )}
    </section>
  );
}

export default Sec1;
