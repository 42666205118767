import "./PopupSubmitSuccess.scss";

function PopupSubmitSuccess(props) {
  const { onClose } = props;

  return (
    <div className="popup-submit-success">
      <div className="top-popup">
        <div className="img-wrapper">
          <img
            src={require("assets/icons/submit-success.png")}
            className="img-success"
            alt=""
          />
        </div>
        <button type="button" className="btn-close" onClick={onClose}>
          <img
            src={require("assets/icons/close_black.png")}
            className="icon-close"
            alt=""
          />
        </button>
      </div>
      <div className="content-popup">
        <div className="title-popup">Thank you</div>
        <p className="content-popup-text">You have submitted successfully</p>
      </div>
    </div>
  );
}

export default PopupSubmitSuccess;
