import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import MainLayout from "./layout/MainLayout";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { mainRoutes } from "routes/routes";
import { useEffect, useState } from "react";

function App() {
  const [menuActive, setMenuActive] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loading, setLoading] = useState(true);
  const [delayAnimation, setDelayAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setDelayAnimation(true);
    }, 500);
    setTimeout(() => {
      setDelayAnimation(true);
    }, 1000);
  }, []);

  const handleScroll = (e) => {
    setScrollPosition(e.target.scrollTop);
  };

  const handleSelectMenu = (menuSelect) => {
    setMenuActive(menuSelect);
  };

  return (
    <div className="App">
      <div className="landing-container">
        <div className="landing-body" onScroll={(e) => handleScroll(e)}>
          <Routes>
            {mainRoutes.map((route, index) => {
              const Page = route.component;
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <MainLayout>
                      <Page
                        menuSelect={menuActive}
                        scrollPosition={scrollPosition}
                        onSelectMenu={handleSelectMenu}
                        delayAnimation={delayAnimation}
                      />
                    </MainLayout>
                  }
                />
              );
            })}
            <Route path="*" element={<Navigate to="/not-found" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
