import "./Popup.scss";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { animated, useTransition } from "react-spring";

function Popup(props) {
  const {
    children,
    isShow,
    onClose,
    center,
    bgWhite,
    top,
    notBackdrop,
    fullHeight,
  } = props;
  const transition = useTransition(isShow, {
    from: { transform: "translateY(20px)", opacity: 0 },
    enter: { transform: "translateY(0)", opacity: 1 },
    leave: { transform: "translateY(20px)", opacity: 0 },
    config: { tension: 500, friction: 50 },
  });
  return createPortal(
    <div className={classNames("popup-confirm", { bgWhite: bgWhite })}>
      {transition(
        (style, item) =>
          item && (
            <animated.div
              style={style}
              className={classNames("popup-confirm-content", {
                center: center,
                bgWhite: bgWhite,
                top: top,
                fullHeight,
              })}
            >
              {children}
            </animated.div>
          )
      )}
      {!notBackdrop ? (
        <div className="back-drop" onClick={onClose} />
      ) : (
        <div className="back-drop" />
      )}
    </div>,
    document.getElementById("root")
  );
}

export default Popup;
