import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import "./MainLayout.scss";

function MainLayout(props) {
  const { children } = props;

  return (
    <div className="layout-wrapper">
      <Navbar />
      <div className="layout-container">{children}</div>
      <Footer />
    </div>
  );
}

export default MainLayout;
