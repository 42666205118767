import classNames from "classnames";
import "./Sec3.scss";
import { useEffect, useState } from "react";

function Sec3(props) {
  const { scrollPosition } = props;
  const [showAnimate, setShowAnimate] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 450) {
      if (scrollPosition >= 1650) {
        setShowAnimate(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 3300) {
        setShowAnimate(true);
      }
    } else {
      if (scrollPosition >= 1500) {
        setShowAnimate(true);
      }
    }
    console.log(scrollPosition);
  }, [scrollPosition]);

  return (
    <section id="sec3" className="sec3-wrapper">
      <div className="sec3-container">
        <div className={classNames("sec3-content", { animate: showAnimate })}>
          <div className="sec3-left">
            <img
              src={require("assets/icons/sec3-img1.png")}
              className="img-sec3"
              alt=""
            />
          </div>
          <div className="sec3-right">
            <div className="header-sec3-right">
              <h3 className="title-header-sec3">Port-folio</h3>
              <div className="content-header-sec3">
                Choose <b>a professional design</b>
              </div>
            </div>
            <div className="bottom-sec3-right">
              <p className="content-bottom-right">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution
              </p>
            </div>
          </div>
          <div className="bg-wrapper">
            <img
              src={require("assets/icons/sec3-bg1.png")}
              className="img-bg mr"
              alt=""
            />
            <img
              src={require("assets/icons/sec3-bg2.png")}
              className="img-bg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sec3;
