import { useNavigate } from "react-router-dom";
import "./Footer.scss";

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="footer-left">
          <img
            src={require("assets/icons/logo.png")}
            className="logo-footer"
            alt=""
          />
          <div className="footer-content">
            <div className="footer-item">
              <img
                src={require("assets/icons/place.png")}
                className="icon-footer"
                alt=""
              />
              <p className="text-footer">
                Robinsons Summit Center, Ayala Avenue, Makati,Metro Manila,
                Philippines
              </p>
            </div>
            <div className="footer-item">
              <img
                src={require("assets/icons/mail.png")}
                className="icon-footer"
                alt=""
              />
              <p className="text-footer">support@cricket.com</p>
            </div>
            <div className="footer-item">
              <img
                src={require("assets/icons/phone.png")}
                className="icon-footer"
                alt=""
              />
              <p className="text-footer">+(632) 833-8127</p>
            </div>
          </div>
        </div>
        <div className="footer-right">
          <div className="list-link">
            <a
              href="#sec1"
              className="link-footer"
              onClick={() => navigate("/")}
            >
              Home
            </a>
            <a
              href="#sec3"
              className="link-footer"
              onClick={() => navigate("/")}
            >
              About us
            </a>
          </div>
          <div className="list-link">
            <a
              href="#sec2"
              className="link-footer"
              onClick={() => navigate("/")}
            >
              Services
            </a>
            <a
              href="#sec4"
              className="link-footer"
              onClick={() => navigate("/")}
            >
              Clients
            </a>
          </div>
        </div>
      </div>
      <div className="copy-right">
        <p className="footer-last">®2023 WASP. All rights reserved</p>
      </div>
    </div>
  );
}

export default Footer;
