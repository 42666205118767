import { useNavigate, useParams } from "react-router-dom";
import "./DetailJob.scss";
import { Administrator, BackEnd, Design, FrontEnd, Tester } from "./JobData";
import { useEffect, useState } from "react";

import Form from "components/Form/Form";

function DetailJob() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isContent, setIsContent] = useState(false);

  useEffect(() => {
    if (Number(id) <= 5) {
      setIsContent(true);
    } else {
      navigate("/not-found");
    }
  }, [id]);

  const handleBackPage = () => {
    navigate("/job");
  };

  return (
    isContent && (
      <div className="detail-jobs">
        <div className="detail-wrapper">
          <div className="top-detail">
            <div className="back-page" onClick={handleBackPage}>
              <img
                src={require("assets/icons/back.png")}
                className="icon-back"
                alt=""
              />
              <div className="text-back">
                Back to <b>Jobs Listing</b>
              </div>
            </div>
            <div className="line"></div>
          </div>
          <div className="content-detail-wrapper">
            {Number(id) === 1 && <Administrator />}
            {Number(id) === 2 && <FrontEnd />}
            {Number(id) === 3 && <BackEnd />}
            {Number(id) === 4 && <Tester />}
            {Number(id) === 5 && <Design />}
            <div className="apply-job">
              <div className="form-wrapper">
                <Form type="APPLY_JOB" />
              </div>
              <div className="contact-title">
                <h2 className="title-h2">Apply Job</h2>
                <p className="title-span">Join us in shaping the future!</p>
                <p className="title-p">
                  At Wasp, we believe in creating a work environment that
                  fosters growth, innovation, and collaboration. We offer a
                  range of diverse roles across various departments, providing
                  ample opportunities for professional development and personal
                  fulfillment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default DetailJob;
